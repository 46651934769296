import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  ApplicationRef,
  ComponentRef,
} from "@angular/core";
import { AlertComponent } from "@shared/components/alert/alert.component";
import { Alert } from "@shared/enums/alert.types";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  changeUpdateBarStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    private _Injector: Injector,
    private _AppRef: ApplicationRef,
    private _ComponentFactoryResolver: ComponentFactoryResolver
  ) {}
  // TODO: find a better way to do this rather than using componentFactoryResolver
  alert(
    type: Alert,
    projectionContent: string,
    timer: number = 3000
  ): ComponentRef<AlertComponent> | undefined {
    const alertComponentFactory =
      this._ComponentFactoryResolver.resolveComponentFactory(AlertComponent);
    const alertComponentRef = alertComponentFactory.create(this._Injector);

    alertComponentRef.instance.type = type;
    alertComponentRef.instance.timer = timer;

    this._AppRef.attachView(alertComponentRef.hostView);

    const domElem = (alertComponentRef.hostView as any)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    setTimeout(() => {
      const projectionContainer =
        alertComponentRef.location.nativeElement.querySelector(
          ".projection-container"
        );
      if (projectionContainer) {
        // Convert First letter to upper case
        projectionContainer.innerHTML = projectionContent.charAt(0).toUpperCase() + projectionContent.slice(1) ;
      }
    }, 0);

    return alertComponentRef;
  }

  destroy(componentRef: ComponentRef<any>): void {
    this._AppRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }
}
