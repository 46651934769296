import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnInit,
} from "@angular/core";
import { AlertService } from "../../services/alert.service";
import { Alert } from "@shared/enums/alert.types";

@Component({
  selector: "alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
  animations: [
    trigger("fadeInOut", [
      state("void", style({ top: "0" })),
      state("*", style({ top: "104px", position: "fixed", "z-index": "9999" })),
      transition("void => *", animate("200ms ease-in")),
      transition("* => void", animate("100ms ease-out")),
    ]),
  ],
  standalone: true,
})
export class AlertComponent implements OnInit {
  @Input() type: Alert = Alert.SUCCESS;
  @Input() componentRef!: ComponentRef<AlertComponent>;
  @Input() timer!: number;
  isVisible: boolean = true;
  types = Alert;

  constructor(
    private _AlertService: AlertService,
    private _Host: ElementRef<HTMLElement>
  ) {}
  ngOnInit(): void {
    setTimeout(() => {
      this.onFadeOut();
    }, this.timer);
  }

  onFadeOut() {
    if (this.componentRef) {
      this._AlertService.destroy(this.componentRef);
    } else {
      this._Host.nativeElement.remove();
    }
  }

  destroySelf(): void {
    this.isVisible = false;
    this.onFadeOut();
  }
}
